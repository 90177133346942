import axios, { AxiosInstance } from "axios";
// import router from "./router";

const apiClient: AxiosInstance = axios.create({
    // baseURL: process.env.VUE_APP_BASE_API_URL,
    baseURL: "http://localhost:8000/api",
    // baseURL: "/api",
    withCredentials: true,
    xsrfHeaderName: 'X-CSRF-Token', // 変更の必要があればここを修正？ クッキーからの取得を追加する必要あり。axiosはCookieにXSRF-TOKENがあると、リクエスト時に「X-CSRF-Token」でヘッダを送るようになっている。
    headers: {
        "Content-type": "application/json",
        "X-Requested-With": "XMLHttpRequest",
        "Access-Control-Allow-Origin": "*",
    },
});

apiClient.interceptors.request.use(request => {
    console.log("axios.interceptors.request = " + JSON.stringify(request));
    return request;
});

apiClient.interceptors.response.use(response => {
    console.debug("axios.interceptors.response.status = " + response.status);
    return response
},
async (error) => {
    console.log("axios.interceptors.response.error = " + JSON.stringify(error));
    // const status = error.response.status;

    // Session expires
    // if (status === 419 || status === 401) {
    //     router.push({ path: '/login' });
    // }

    return Promise.reject(error);
});

export default apiClient;
