import { createI18n } from "vue-i18n";
import enUS from './data/en.json'
import jaJP from './data/ja.json'

const i18n = createI18n<false>({
    legacy: false,
    locale: 'ja',
    fallbackLocale: 'en',
    globalInjection: true,
    messages: {
        'en': enUS,
        'ja': jaJP
    },
    datetimeFormats: {
        'ja': {
        short: {
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric',
            timeZoneName: 'short',
        }
        }
    },
    numberFormats: {
        'ja': {
        currency: {
            style: 'currency',
            currencyDisplay: 'symbol',
            currency: 'JPY'
        }
        }
    }
})

export default i18n;

