import useDetailPage from './components/admin/use_details/details.vue'
import useRegistrationPage from './components/registration/registration.vue'
import registrationDetai from './components/registration/registrationDetail.vue'
import registrationFinished from './components/registration/LoginFinished.vue'
import guestCardRegist from './components/admin/guest_card/guestCardRegist.vue'

import changeEmailPage from './components/registration/changeEmail.vue'
import changeEmailaddressPage from './components/registration/changeEmailaddress.vue'

const routes = [
    {
        path: '/useDetail/',
        component: useDetailPage,
    },
    {
        path: '/:token/registration',
        component: useRegistrationPage,
        routes: [
            {
                path: '/detail/',
                component: registrationDetai,
            },
            {
                path: '/LoginFinished/',
                component: registrationFinished,
                options: {
                    transition: 'f7-dive',
                },
            },
            {
                path: '/changeEmail/',
                component: changeEmailPage,
            },
            {
                path: '/changeEmailaddress/',
                component: changeEmailaddressPage,
                options: {
                    transition: 'f7-dive',
                },
            },
        ],
    },
    {
        path: '/:token/guestRegist/',
        props: {
            foo: 'bar'
        },
        component: guestCardRegist,
    },
    // ...
];

export default routes;
