<template>
    <f7-app v-bind="f7params">
        <f7-view main url="/useDetail/" :browserHistory="true"  browserHistorySeparator="#" browserHistoryRoot></f7-view>
    </f7-app>
</template>

<script>
import router from './router.ts'
export default {
    themeChangeListener: null,
    data() {
        return {
            f7params: {
                name: 'hotel OS',
                id: 'com.hotelOS',
                routes: router
            }
        }
    },
}
</script>