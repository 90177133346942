import http from "@/http-common";
import { CConvertor } from "@/utils/c-convertor";
import { IRegistrationResponse } from '@/interface/api/registration/i-registration-response';
import { IRegistrationComponent } from '@/interface/component/registration/i-registration-component';

export default class RegistrationService {

    // レジ情報 詳細取得
    getRegistration () {
        const sendData: { [key: string]: number; } = {
            group_id: 1,
            company_id: 1,
            store_id: 1,
            guest_id: 1,
        };
        return new Promise<IRegistrationComponent>((resolve, reject) => {
            try {
                http.post("/registration", CConvertor.toSnakeCaseObject(sendData)
                ).then(response => {
                    const data =  CConvertor.toCamelCaseObject(response.data) as IRegistrationResponse;
                    console.log(data.code);
                    const bBasicInformation = data.result as IRegistrationComponent[];
                    resolve(bBasicInformation[0]);
                }).catch(response => {
                    console.log("getRegistration Error");
                    console.log(response);
                    reject(response);
                });
            } catch(e) {
                console.log(e+"処理失敗");
                reject(e);
            }
        });
    }
}
