import 'framework7-icons/css/framework7-icons.css';

import { createApp, reactive } from 'vue';
import Framework7 from "framework7/lite/bundle";
import Framework7Vue from 'framework7-vue';

import "framework7/css/bundle";
// Pinia
import { createPinia } from "pinia";
// Pinia stores
// import { useAuthStore } from '@/store/auth'

// Internationalization
import i18n from './locale/i18n'
import AppWrapper from './AppWrapper.vue';
import OnMountedFocus from './directives/MountedFocus';

import { f7AccordionContent, f7AccordionItem, f7AccordionToggle, f7Accordion, f7ActionsButton, f7ActionsGroup, f7ActionsLabel, f7Actions, f7App, f7Appbar, 
    f7AreaChart, f7Badge, f7BlockFooter, f7BlockHeader, f7BlockTitle, f7Block, f7BreadcrumbsCollapsed, f7BreadcrumbsItem, f7BreadcrumbsSeparator, f7Breadcrumbs, 
    f7Button, f7CardContent, f7CardFooter, f7CardHeader, f7Card, f7Checkbox, f7Chip, f7Col, f7FabBackdrop, f7FabButton, f7FabButtons, f7Fab, f7Gauge, f7Icon, 
    f7Input, f7Link, f7ListButton, f7ListGroup, f7ListIndex, f7ListInput, f7ListItemCell, f7ListItemRow, f7ListItem, f7List, f7View, 
    f7Navbar, f7Page, f7NavLeft, f7NavRight,f7Toolbar,f7Tabs, f7Tab,
    f7Panel, f7Row, f7Popup } from 'framework7-vue';

    // f7LoginScreenTitle, f7LoginScreen, f7MenuDropdownItem, f7MenuDropdown, f7MenuItem, f7Menu, f7Message, f7MessagebarAttachment, f7MessagebarAttachments, f7MessagebarSheetImage, f7MessagebarSheetItem, 
    // f7MessagebarSheet, f7Messagebar, f7MessagesTitle, f7Messages,  f7NavTitleLarge, f7NavTitle,  f7PageContent,  
    // f7PhotoBrowser, f7PieChart, f7Popover, f7Preloader, f7Progressbar, f7Radio, f7Range, f7RoutableModals, f7Searchbar, f7Segmented, f7Sheet, 
    // f7SkeletonAvatar, f7SkeletonBlock, f7SkeletonImage, f7SkeletonText, f7Stepper, f7Subnavbar, f7SwipeoutActions, f7SwipeoutButton, f7SwiperSlide, f7Swiper, 
    //  f7TextEditor, f7Toggle, f7Toolbar, f7TreeviewItem, f7Treeview, f7UseIcon,  f7Views 

Framework7.use(Framework7Vue)

const app = createApp(AppWrapper);

// Register the components you need
app.component('f7List', f7List);
app.component('f7ListInput', f7ListInput);

app.component('f7ListItem', f7ListItem);
app.component('f7ListGroup', f7ListGroup);
app.component('f7ListIndex', f7ListIndex);

app.component('f7ListItemCell', f7ListItemCell);
app.component('f7ListItemRow', f7ListItemRow);
app.component('f7Gauge', f7Gauge);
app.component('f7Icon', f7Icon);
app.component('f7Input', f7Input);
app.component('f7ListButton', f7ListButton);

app.component('f7AccordionContent', f7AccordionContent);
app.component('f7AccordionItem', f7AccordionItem);
app.component('f7AccordionToggle', f7AccordionToggle);
app.component('f7Accordion', f7Accordion);
app.component('f7ActionsButton', f7ActionsButton);
app.component('f7ActionsGroup', f7ActionsGroup);
app.component('f7ActionsLabel', f7ActionsLabel);
app.component('f7Actions', f7Actions);
app.component('f7App', f7App);
app.component('f7Appbar', f7Appbar);
app.component('f7AreaChart', f7AreaChart);
app.component('f7Badge', f7Badge);
app.component('f7BlockFooter', f7BlockFooter);
app.component('f7BlockHeader', f7BlockHeader);
app.component('f7BlockTitle', f7BlockTitle);
app.component('f7Block', f7Block);
app.component('f7BreadcrumbsCollapsed', f7BreadcrumbsCollapsed);
app.component('f7BreadcrumbsItem', f7BreadcrumbsItem);
app.component('f7BreadcrumbsSeparator', f7BreadcrumbsSeparator);
app.component('f7Breadcrumbs', f7Breadcrumbs);
app.component('f7Button', f7Button);
app.component('f7CardContent', f7CardContent);
app.component('f7CardFooter', f7CardFooter);
app.component('f7CardHeader', f7CardHeader);
app.component('f7Card', f7Card);
app.component('f7Checkbox', f7Checkbox);
app.component('f7Chip', f7Chip);
app.component('f7FabBackdrop', f7FabBackdrop);
app.component('f7FabButton', f7FabButton);
app.component('f7FabButtons', f7FabButtons);
app.component('f7Fab', f7Fab);
app.component('f7View', f7View);
app.component('f7Panel', f7Panel);
app.component('f7Navbar', f7Navbar);
app.component('f7NavLeft', f7NavLeft);
app.component('f7NavRight', f7NavRight);
app.component('f7Page', f7Page);
app.component('f7Row', f7Row);
app.component('f7Col', f7Col);
app.component('f7Popup', f7Popup);
app.component('f7Link', f7Link);
app.component('f7Toolbar', f7Toolbar);
app.component('f7Tabs', f7Tabs);
app.component('f7Tab', f7Tab);

app.config.globalProperties.$appState = reactive({ theme: 'lara-light-indigo', darkTheme: false });
app.provide("$appState", app.config.globalProperties.$appState);

app.use(createPinia());

// Internationalization
app.use(i18n);

// `v-focus` と呼ばれるグローバルカスタムディレクティブを登録します
app.directive('focus', OnMountedFocus);

app.mount('#app');