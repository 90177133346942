
<!-- <script lang="ts" setup>
  import { ref } from 'vue';
  const zip = ref('123');
  const pref_id = ref('1');
  const locality = ref('2');
  const street = ref('3');
  const input = (e) => {
    zip.value = e.target.value;
    console.log('zip.value', zip.value);
  }
  const load = () => {
    console.log('zip.value::',zip.value);
      eslint-disable-next-line no-undef
      new YubinBango.Core(zip.value, function(addr) {
          pref_id.value  = addr.region // 都道府県ID
          locality.value = addr.locality  // 市区町村
          street.value   = addr.street  // 町域
          console.log('pref_id.value',pref_id.value);
      })

  }
</script> -->

<template>
  <f7-page style="background-color: #FFF;padding-bottom: 0px;">
    <f7-navbar title="宿泊者カードの登録">
      <f7-button class="col" raised actions-open="#actions-one-group">
        <i class="f7-icons size-22">globe</i>
      </f7-button>
    </f7-navbar>
    <!-- One Group -->
    <f7-actions id="actions-one-group">
      <f7-actions-group>
        <f7-actions-label>言語</f7-actions-label>
        <f7-actions-button bold>日本語</f7-actions-button>
        <f7-actions-button>英語</f7-actions-button>
      </f7-actions-group>
    </f7-actions>
    <f7-card no-shadow>
      <f7-card-header class="title">予約内容</f7-card-header>
      <f7-card-content>
        <f7-row>
          <f7-col class="title">予約番号</f7-col>
          <f7-col>2502</f7-col>
        </f7-row>
        <f7-row>
          <f7-col class="title">ホテル名</f7-col>
          <f7-col>Hotel HOS-1-1</f7-col>
        </f7-row>
        <f7-row>
          <f7-col class="title">予約者名</f7-col>
          <f7-col>坂本 龍馬</f7-col>
        </f7-row>
        <f7-row>
          <f7-col class="title">チェックイン日</f7-col>
          <f7-col>2023/01/25 15:50</f7-col>
        </f7-row>
        <f7-row>
          <f7-col class="title">チェックアウト日</f7-col>
          <f7-col>2023/01/27</f7-col>
        </f7-row>
        <f7-row>
          <f7-col class="title">泊数</f7-col>
          <f7-col>2</f7-col>
        </f7-row>
        <f7-row><f7-col>&nbsp;</f7-col></f7-row>
        <f7-row>
          <f7-col style="color: red;">宿泊者のお客様と異なる場合は、メールアドレスの登録をお願いいたします。</f7-col>
        </f7-row>
        <f7-row>
          <f7-col>
            <f7-block strong>
              <f7-button fill large href="/:token/registration/changeEmail/"> メールアドレスの変更</f7-button>
            </f7-block>
          </f7-col>
        </f7-row>
      </f7-card-content>
    </f7-card>
    <!-- Page content -->
    <f7-list no-hairlines style="width:85%;margin: 0 auto;">
      <f7-list-input type="text" outline label="氏名（必須）" floating-label required validate clear-button></f7-list-input>
      <f7-list-input type="text" outline label="カナ（必須）" floating-label required validate clear-button></f7-list-input>
      <f7-list-input label="生年月日" floating-label outline clear-button type="datepicker" readonly
        v-model:value="brisday"></f7-list-input>
      <f7-list-input type="number" maxlength="3" outline label="年齢" floating-label clear-button></f7-list-input>
      <f7-list-input type="select" outline label="国籍" floating-label>
        <option value=""></option>
        <option value="Male">日本</option>
        <option value="Female">中国</option>
        <option value="Female">アメリカ</option>
      </f7-list-input>
      <f7-list-input class="post-style" type="number" label="郵便番号（必須）" outline floating-label clear-button required
        validate pattern="[0-9]*" v-model:value="postNum">
        <template v-slot:media>
          <f7-button button-large-md fill @click="load">住所検索</f7-button>
        </template>
      </f7-list-input>
      <f7-list-input name="prefecture" type="text" label="都道府県（必須）" outline floating-label clear-button required
        v-model:value="prefId" validate></f7-list-input>
      <f7-list-input name="city" type="text" label="市区町村（必須）" outline floating-label clear-button required
        v-model:value="locality" validate></f7-list-input>
      <f7-list-input type="text" label="番地、マンション名（必須）" outline floating-label clear-button required
        v-model:value="street" validate></f7-list-input>
      <f7-list-input type="text" label="電話番号（必須）" outline floating-label clear-button required validate
        pattern="[0-9]*"></f7-list-input>
      <f7-list-input type="text" label="携帯電話番号" outline floating-label clear-button></f7-list-input>
      <f7-list-input type="text" label="会社電話番号" outline floating-label clear-button></f7-list-input>
      <f7-list-input type="text" label="会社名" outline floating-label clear-button></f7-list-input>
      <f7-list-input type="text" label="領受書宛先（必須）" outline floating-label clear-button required validate></f7-list-input>
      <div class="rule-text">
        <div>
          <input class="checkbox-size" type="checkbox" id="scales" name="scales">
          <label for="scales">次回以降この情報を利用する</label>
        </div>
        <div>
          <input class="checkbox-size" type="checkbox" id="horns" name="horns">
          <label for="horns" style="vertical-align: middle;">利用规约に同意する&nbsp;<f7-badge color="red"
              style="vertical-align: middle;">必須</f7-badge>（<a href="">規約を表示</a>）</label>
        </div>
        <div>
          <input class="checkbox-size" type="checkbox" id="horns2" name="horns2">
          <label for="horns2">チェックアウトサービスを利用する（<a href="">詳細</a>）</label>
        </div>
      </div>
    </f7-list>
    <f7-block>
      <f7-button fill preloader large href="/:token/registration/LoginFinished/"> 宿泊者カードを登録する </f7-button>
    </f7-block>
  </f7-page>
</template>
<script >
export default {

  data() {
    return {
      postNum: '',
      prefId: '',
      locality: '',
      street: '',
      brisday: new Date(),
    }
  },
  methods: {
    load() {
      const _this = this;
      //eslint-disable-next-line no-undef
      new YubinBango.Core(this.postNum, function (addr) {
        _this.prefId = addr.region // 都道府県ID
        _this.locality = addr.locality  // 市区町村
        _this.street = addr.street  // 町域
      });
    }
  }
}
</script>

<style scoped>
.title {
  font-weight: 900;
}

.page>>>.page-content {
  padding-bottom: 0px;
}

/* .required-label {
  position: relative;
} */
/* .required-label::after {
  content: '※必須';
  position: absolute;
  font-size: 5px;
  color: red;
  bottom: 8px;
  right: -40px;

} */
.row-height {
  height: 35px;
  margin-top: 15px;
}

.list>>>.item-input-error-message {
  display: none !important;
  ;
}

.list>>>.item-input-wrap {
  margin-bottom: 0px !important;
}

.list>>>.item-content {
  padding: 0px !important;
}

.list>>>.item-inner {
  padding: 0px 0px 5px 0px !important;
}

.post-style>>>.item-content {
  flex-flow: row-reverse;
}

.post-style>>>.item-media {
  align-self: auto !important;
}

.post-style>>>.item-inner {
  margin: 0px 50px 0px 0px;
}

.marginblock {
  margin-block: 2px !important;
}

.checkbox-size {
  zoom: 170%;
  vertical-align: middle;
}

.postal-code {
  display: flex;
  align-items: center;
  list-style-type: none;
}

.rule-text label {
  font-size: 15px;
}
</style>