<template>
  <f7-page :page-content="false">
    <f7-navbar title="宿泊者カードの登録">
    </f7-navbar>
    <f7-block>&nbsp;</f7-block>
    <f7-card class="demo-facebook-card">
      <f7-card-header class="total">
        予約内容{{reservationInfo.bookingNo}}
      </f7-card-header>
      <f7-card-content>
        <f7-row>
          <f7-col>【2015/11/27】</f7-col>
        </f7-row>
        <f7-row>
          <f7-col>宿泊代</f7-col>
          <f7-col style="text-align:right;">￥12,000</f7-col>
        </f7-row>
        <f7-row>
          <f7-col>クリーニンク:ワイシャツ</f7-col>
          <f7-col style="text-align:right;">￥4,000</f7-col>
        </f7-row>
        <f7-row>
          <f7-col></f7-col>
          <f7-col>
            <f7-row no-gap>
              <f7-col width="45">合計</f7-col>
              <f7-col width="55" style="text-align:right;">￥16,000</f7-col>
            </f7-row>
          </f7-col>
        </f7-row>
        <f7-row>
          <f7-col></f7-col>
          <f7-col>
            <f7-row no-gap>
              <f7-col width="45">内消費税</f7-col>
              <f7-col width="55" style="text-align:right;">￥123</f7-col>
            </f7-row>
          </f7-col>
        </f7-row>
        <f7-row>
          <f7-col></f7-col>
          <f7-col>
            <f7-row no-gap>
              <f7-col width="45">内その他税</f7-col>
              <f7-col width="55" style="text-align:right;">￥123</f7-col>
            </f7-row>
          </f7-col>
        </f7-row>
        <f7-row>
          <f7-col></f7-col>
          <f7-col>
            <f7-row no-gap>
              <f7-col width="45">前受け金</f7-col>
              <f7-col width="55" style="text-align:right;">￥123</f7-col>
            </f7-row>
          </f7-col>
        </f7-row>
        <f7-row class="total">
          <f7-col>ご請求金額</f7-col>
          <f7-col style="text-align:right;">￥123</f7-col>
        </f7-row>
        <f7-row><f7-col>&nbsp;</f7-col></f7-row>
        <f7-row>
          <f7-col>
            <f7-block strong>
              <f7-button fill large href="#"> クレジット支払</f7-button>
            </f7-block>
          </f7-col>
        </f7-row>
        <f7-row><f7-col>&nbsp;</f7-col></f7-row>
        <f7-row>
          <f7-col>クレジットカードで支払いいただくことで、チェックアウトを行うことができます。</f7-col>
        </f7-row>
      </f7-card-content>
    </f7-card>



    <f7-toolbar tabbar position="bottom">
      <f7-link>このサイトについて</f7-link>
      <f7-link>利用規約</f7-link>
      <f7-link>プライバシーポリシー</f7-link>
    </f7-toolbar>
  </f7-page>
</template>

<script>
import RegistrationService from '@/service/registration/RegistrationService';
export default {
  data() {
			return {
				reservationInfo:{ bookingNo: '', guestName: '', checkInDate: '', checkOutDate: '', nights: '' },
        testx: 124,
			}
	},
  async mounted() {
    const registrationService = new RegistrationService();
    this.reservationInfo =  await registrationService.getRegistration();
  }

}

</script>

<style>
.toolbar .link {
  color: #333;
  font-size: small;
}

.total {
  font-weight: 900;
  font-size: large;
}

.row {
  min-height: 25px;
  align-items: center;
}
</style>
